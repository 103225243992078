import * as React from "react";
import {
    Root,
    ContentContainer,
    PageHeading,
    PageDescription,
    BorderContainer,
    SectionContainer,
    PriceContainer,
    PriceText,
    BoldText,
    FeatureContainer,
    Feature,
    IconContainer,
    TipContainer,
} from "./PricingContent.styles";
import { RoundCheckIcon } from "../../../Icons/RoundCheckIcon";
import { useTranslation, Trans } from "react-i18next";
import { PaypalPurchase } from "./PaypalPurchase";
import { TelemetryLink } from "../../../TelemetryLink/TelemetryLink";
import { Constants } from "../../../../utilities/Constants";
import { CoderOneRoute } from "../../../Pages/CoderOneRoute";
import { Tag } from "../../../Tag/Tag";

export const EntryTicketContent: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <Root>
            <ContentContainer>
                <div>
                    <PageHeading>{t("entryTicketPage.heading")}</PageHeading>
                    <PageDescription>
                        <Trans key="entryTicketPage.coderOneValueProposition">
                            Coder One is a fun way to improve your skills, win prizes and find job opportunities. Join our{" "}
                            <BoldText>800+</BoldText> member community by purchasing an Entry Ticket for the season.
                        </Trans>
                    </PageDescription>
                    <PageDescription>
                        <Trans key="entryTicketPage.studentClubsNote">
                            <BoldText>Are you currently a university student?</BoldText> You may be eligible for free entry via one of our
                            select university student club partners around Asia-Pacific.{" "}
                            <TelemetryLink href={Constants.StudentClubPage} target="_blank">
                                Check this page for more details.
                            </TelemetryLink>
                        </Trans>
                    </PageDescription>
                    <PageDescription>
                        <Trans key="entryTicketPage.specialContactNote">
                            If you're experiencing any issues, please contact us at{" "}
                            <TelemetryLink href={Constants.MailToLink}>humans@gocoder.one</TelemetryLink>.
                        </Trans>
                    </PageDescription>
                </div>
                <div>
                    <BorderContainer>
                        <SectionContainer>
                            <Tag>🎫 {t("entryTicketPage.title")}</Tag>
                            <PriceContainer>
                                <BoldText>{t("entryTicketPage.entryTicketCurrency")}</BoldText>
                                <PriceText>{t("entryTicketPage.entryTicketCost")}</PriceText>
                            </PriceContainer>
                            <FeatureContainer>
                                <BoldText>{t("entryTicketPage.entryTicketGivesYou")}</BoldText>
                                <Feature>
                                    <IconContainer>
                                        <RoundCheckIcon />
                                    </IconContainer>
                                    {t("entryTicketPage.featureOne")}
                                </Feature>
                                <Feature>
                                    <IconContainer>
                                        <RoundCheckIcon />
                                    </IconContainer>
                                    {t("entryTicketPage.featureTwo")}
                                </Feature>
                                <Feature>
                                    <IconContainer>
                                        <RoundCheckIcon />
                                    </IconContainer>
                                    {t("entryTicketPage.featureThree")}
                                </Feature>
                                <Feature>
                                    <IconContainer>
                                        <RoundCheckIcon />
                                    </IconContainer>
                                    {t("entryTicketPage.featureFour")}
                                </Feature>
                            </FeatureContainer>
                            <PaypalPurchase type="subscription" />
                            <TelemetryLink href={CoderOneRoute.Redeem}>{t("entryTicketPage.haveInvitationCode")}</TelemetryLink>
                        </SectionContainer>
                    </BorderContainer>
                    <TipContainer>
                        <Trans key="entryTicketPage.tip">
                            <BoldText>💡 Tip:</BoldText> you may be able to expense your Entry Ticket with your organization as part of a
                            Learning and Development (L&D) program
                        </Trans>
                    </TipContainer>
                </div>
            </ContentContainer>
        </Root>
    );
};
