import * as React from "react";
import { Footer } from "../../../Footer/Footer";
import { NavigationHeader } from "../../../NavigationHeader/NavigationHeader";
import { SEO } from "../../../SEO/SEO";
import { useTranslation } from "react-i18next";
import coderOnePreview from "../../../Images/coder-one.jpg";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { EntryTicketContent } from "./EntryTicketContent";

const EntryTicketPricing: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const description = t("entryTicketPage.description");
    const title = t("entryTicketPage.title");
    const socialImage = `https://${process.env.GATSBY_HOST}${coderOnePreview}`;
    return (
        <React.Fragment>
            <PayPalScriptProvider
                options={{
                    "client-id": process.env.GATSBY_PAYPAL_CLIENT_ID ?? "",
                    components: "buttons",
                    intent: "capture",
                    vault: true,
                }}
            >
                <SEO title={title} description={description} socialImage={socialImage} />
                <NavigationHeader isDarkTheme={true} />
                <EntryTicketContent />
                <Footer isDarkTheme={true} />
            </PayPalScriptProvider>
        </React.Fragment>
    );
};

export default EntryTicketPricing;
