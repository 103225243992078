import styled from "styled-components";
import { Palette } from "../../../../theme/Palette";
import { Font } from "../../../../utilities/Constants/Font";
import { Viewport } from "../../../../utilities/Constants/Viewport";
import BackgroundTile from "../Home/AboveFold/pixel-space-tile.png";

export const Root = styled.div`
    background-image: linear-gradient(180deg, ${Palette.Neutral100} 10%, transparent 50%),
        radial-gradient(${Palette.Accent10}, transparent 40%), url(${BackgroundTile});
    background-position: 50% 100%;
    background-repeat: repeat;
    background-attachment: scroll, scroll, fixed;
`;

export const ContentContainer = styled.div`
    max-width: 1200px;
    padding: 160px 16px;
    margin: 0 auto;
    text-align: left;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 32px;

    @media screen and (max-width: ${Viewport.Large}px) {
        display: flex;
        flex-direction: column;
        gap: 32px;
        text-align: center;
    }
`;

export const PageHeading = styled.h1`
    font-family: ${Font.SpaceGrotesk};
    font-size: 64px;
    line-height: 120%;
    font-weight: 700;
    color: ${Palette.Neutral0};
    padding-bottom: 16px;

    @media screen and (max-width: ${Viewport.Small}px) {
        font-size: 36px;
    }
`;

export const PageDescription = styled.p`
    color: ${Palette.White80};
`;

export const BorderContainer = styled.div`
    background-image: linear-gradient(135deg, ${Palette.Primary100} 10%, ${Palette.Accent100} 50%);
    padding: 8px;
    border-radius: 4px;
    max-width: 580px;
    margin: 0px auto;
`;

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-items: center;
    background-color: ${Palette.Neutral100};
    padding: 32px;
    max-width: 580px;
    border-radius: 4px;
    color: ${Palette.White80};

    @media screen and (max-width: ${Viewport.Large}px) {
        padding: 32px;
        grid-template-columns: auto;
        justify-items: start;
        grid-row-gap: 32px;
    }
`;

export const PriceContainer = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 4px;
    justify-content: center;
    padding-bottom: 16px;
    margin-left: -16px;
`;

export const PriceText = styled.p`
    font-family: ${Font.SpaceGrotesk};
    font-size: 64px;
    font-weight: 700;
    color: ${Palette.Neutral0};
    line-height: 100%;
`;

export const BoldText = styled.span`
    display: inline;
    color: ${Palette.White100};
    font-weight: 700;
`;

export const FeatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: left;
`;

export const Feature = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 8px;
    align-content: center;
`;

export const IconContainer = styled.div`
    font-size: 16px;
    color: ${Palette.White100};
`;

export const TipContainer = styled.div`
    max-width: 580px;
    color: ${Palette.White80};
    margin: 8px auto 0px auto;
    background-color: ${Palette.Neutral100};
    border: 1px solid ${Palette.White10};
    border-radius: 4px;
    padding: 8px;
    text-align: center;
`;
